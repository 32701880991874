<template>
  <div class="setting-page">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="logo" name="logo">
        <el-alert title="自定义logo，点击图片即可替换" force-aligned type="warning" />
        <el-upload
          class="imgConfig-uploader"
          name="logoFile"
          :headers="uploadHeaders"
          :action="upLogoUrl"
          :data="logoForm"
          :show-file-list="false"
          :on-success="upLogoSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <div v-if="logoImg" class="logoImg-container">
            <img :src="logoImg" class="logoImg">
          </div>
          <i v-else class="el-icon-plus imgConfig-uploader-icon" />
        </el-upload>
      </el-tab-pane>
      <el-tab-pane label="登录背景图" name="bjImg">
        <el-alert title="自定义登录页背景图，点击图片即可替换" type="warning" />
        <el-upload
          class="imgConfig-uploader"
          name="bgPictureFile"
          :headers="uploadHeaders"
          :action="upBgUrl"
          :data="bgForm"
          :show-file-list="false"
          :on-success="upBgSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <div 
            v-if="loginImgbg"
            class="loginImgbg"
            :style="{
              'background-image': loginImgbg
                ? 'url(' + loginImgbg + ')'
                : null,
            }"
          />
          <i v-else class="el-icon-plus imgConfig-uploader-icon" />
        </el-upload>
      </el-tab-pane>
    </el-tabs>
    <!-- <el-button type="primary" size="small" @click="onSubmit">
      保存设置
    </el-button> -->
  </div>
</template>

<script>
import { getEnterpInfo } from '@/api/setting';
import store from '@/store';
export default {
  data () {
    const baseURL = process.env.VUE_APP_API_URL;
    return {
      activeName: 'logo',
      logoImg: require('../../assets/logo-img.png'),
      loginImgbg: require('../../assets/login_bg.png'),
      upLogoUrl: baseURL + 'tenant/setup',
      upBgUrl: baseURL + 'tenant/otherSet',
      uploadHeaders: {
        'Authorization': store.getters.token
      },
      // tenantId 租户ID enterprisevalue 企业名称 logoFile 企业logo文件
      logoForm: {
        tenantId: 'f7c4c29cf92641c0b816f2ed49c396c4',
        enterprisevalue: '商派网络'
      },
      // tenantId 租户ID bgPictureFile 企业背景图文件 bgRedirectUrl 点击图片的跳转地址 isAgreeRule 是否统一规则
      bgForm: {
        tenantId: 'f7c4c29cf92641c0b816f2ed49c396c4',
        enterprisevalue: '商派网络',
        isAgreeRule: true,
        bgRedirectUrl: '',
      },
    };
  },
  mounted () {
    this.fetch();
  },
  methods: {
    fetch () {
      getEnterpInfo().then(res => {
        if (res.entLogo) {
          this.logoImg = res.entLogo;
        }
        if (res.bgPicture) {
          this.loginImgbg = res.bgPicture;
        }

      });
    },
    handleClick () { },
    upLogoSuccess (res, file) {
      console.log(res, file);
      if (res.code === 0) {
        this.fetch();
      }
    },
    upBgSuccess (res, file) {
      console.log(res, file);
      if (res.code === 0) {
        this.fetch();
      }
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.warning('上传图片只能是 JPG/PNG/GIF 格式!');
        return isJPG;
      }
      if (!isLt2M) {
        this.$message.warning('上传图片大小不能超过 2MB!');
        return isLt2M;
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style lang='scss'>
.setting-page {
  .imgConfig-uploader {
    margin-top: 50px;
    margin-left: 140px;
    margin: 50px 0 0 140px;
    .el-upload {
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .el-upload:hover {
      border-color: #409eff;
    }
    &-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .logoImg-container {
      display: inline-block;
      width: 150px;
      vertical-align: middle;
      .logoImg {
        width: 100%;
      }
    }
    .loginImgbg {
      width: 800px;
      height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
</style>
<style lang='scss' scoped>
.setting-page {
  height: 100%;
  background: #fff;
  padding: 24px;
  min-height: 500px;
  .el-input--small {
    width: 300px;
    &.min-input {
      width: 120px;
    }
  }
  .separator {
    margin: 0 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .el-tabs {
    height: 390px;
  }
}
</style>